import { render, staticRenderFns } from "./MobileLayout.vue?vue&type=template&id=273f57e9&scoped=true&"
import script from "./MobileLayout.vue?vue&type=script&lang=js&"
export * from "./MobileLayout.vue?vue&type=script&lang=js&"
import style0 from "./MobileLayout.vue?vue&type=style&index=0&id=273f57e9&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "273f57e9",
  null
  
)

export default component.exports