





















































































import {mapState} from "vuex";
import axios from 'axios';

export default {
  props: ['showPlayBtn'],
  data() {
    return {
      userAddress: '',
      dropDownMenuName: '',
      spaceIdName: '',
    }
  },
  emits: [],
  computed: {
    ...mapState(['accounts']),
    shortenUserAddress(): string {
      const userAddress = this.$store.state.accounts[0];
      if (!userAddress) return "";
      return [
        userAddress.slice(0, 4),
        "...",
        userAddress.slice(-4),
      ].join("");
    }
  },
  watch: {
    async accounts(val) {
      if (!val?.[0]) return;
      const ret = await axios.get('https://farmer.trustfi.org/api/querySid', {
        params: {
          tld: 'bnb',
          address: val[0],
        }
      })
      if (ret.data.code === 0 && ret.data.name) {
        this.spaceIdName = ret.data.name;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.hideAllDropDownMenu)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideAllDropDownMenu)
  },
  methods: {
    hideAllDropDownMenu() {
      this.dropDownMenuName = '';
    },
    showConnectWallet() {
      this.$store.commit('setState', {
        connectWalletShow: true
      })
    },
    openUrl (url: string) {
      window.location.href = url;
    },
    backToHomePage (overridePath: string) {
      this.$router.push({
        path: overridePath ?? '/home'
      })
    },
  }
}
