<template>
  <div class="wrapper">
    <comp-header-mobile ref="headerComp" />
    <slot></slot>
    <comp-footer-mobile />
  </div>
</template>

<script>
import CompHeaderMobile from "@/components/v2/CompHeaderMobile";
import CompFooterMobile from "@/components/v2/CompFooterMobile";

export default {
  name: "MobileLayout",
  components: { CompFooterMobile, CompHeaderMobile },
  methods: {
    connectWallet() {
      this.$refs.headerComp.connectToWallet();
    },
  },
};
</script>

<style scoped lang="less">
.wrapper {
  background-color: #0a072e;
}
</style>
