


























































































































































import {mapState} from "vuex";
import * as metaMaskTools from "@/tools/chain";
import axios from "axios";

export default {
  name: 'CompHeaderMobile',
  data() {
    return {
      showMenu: false,
      dropDownMenuName: '',
      spaceIdName: '',
    }
  },
  computed: {
    ...mapState(['accounts']),
    shortenUserAddress() {
      const userAddress = this.$store.state.accounts[0];
      if (!userAddress) return "";
      return [
        userAddress.slice(0, 4),
        "...",
        userAddress.slice(-4),
      ].join("");
    }
  },
  watch: {
    showMenu(isShow) {
      if (!isShow) {
        this.dropDownMenuName = "";
      }
    },
    async accounts(val) {
      if (!val?.[0]) return;
      const ret = await axios.get('https://farmer.trustfi.org/api/querySid', {
        params: {
          tld: 'bnb',
          address: val[0],
          // address: '0x2e552E3aD9f7446e9caB378c008315E0C26c0398'
        }
      })
      if (ret.data.code === 0 && ret.data.name) {
        this.spaceIdName = ret.data.name;
      }
    },
  },
  methods: {
    openUrl(url: string) {
      window.location.href = url;
    },
    connectToWallet() {
      metaMaskTools.initWeb3().then(() => {
        // do nothing
      });
    },
    logout() {
      this.$store.dispatch('logOut')
    },
    backToHomePage (overridePath: string) {
      this.$router.push({
        path: overridePath ?? '/home'
      })
    },
  }
}
