














import CompHeader from "@/components/v2/CompHeader.vue";
import CompFooter from "@/components/v2/CompFooter.vue";
import Popup from "../popup/Index.vue";

export default {
  name: "Layout",
  components: {
    'comp-header': CompHeader,
    'comp-footer': CompFooter,
    'pop-ups': Popup,
  }
}
