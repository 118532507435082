

















































































import Axios from "axios";

const axios = Axios.create({ baseURL: "https://cheersland.org/" });

export default {
  data() {
    return {
      emailAddress: "",
      emailAddressError: false,
    }
  },
  methods: {
    openUrl(url: string) {
      window.open(url);
    },
    async doSubscribe () {
      if (!this.emailAddress) return;
      const subRet = await axios.post("userSubscribe", {
        address: this.emailAddress,
      });
      if (String(subRet.data.success) === "false") {
        this.emailAddressError = true;
        return;
      }
      window.alert("Email was successfully subscribed");
    }
  }
}
